import { minPasswordLength } from './constants';

export const validatePassword = (password) => {
  if ((password || '').length < minPasswordLength) {
    return translate('Passwords must be at least {{minPasswordLength}} characters in length', {
      minPasswordLength,
    });
  }
};

export const validateRequired = [(value) => (value ? undefined : translate('Field is required'))];

export const validateRequiredDraftJS = [
  (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.innerText ? undefined : translate('Field is required');
  },
];

export const minLength = (min) => (value) =>
  value && value.length < min ? translate('Must be {{min}} characters or more', { min }) : undefined;

export const getValidateRequired = (allowedValue = Symbol.for('allowedValue')) => {
  const required = (value) => (value || value === allowedValue ? undefined : translate('Field is required'));
  return [required];
};

export const validateRichHTML = (tags) => (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const matches = div.innerText.match(/{{(.*?)}}/g) || [];

  const invalidTag = matches.find((match: string) => {
    const strippedMatch = match.replace('{{', '').replace('}}', '').trim();
    const foundTag = tags.find((tag) => tag.code === `{{ ${strippedMatch} }}`);
    if (!foundTag) {
      return strippedMatch;
    }
    return null;
  });

  if (invalidTag) {
    return translate('Tag {{ invalidTag }} not found', {
      invalidTag,
    });
  }
  const openMatches = div.innerText.match(new RegExp('{{', 'g')) || [];
  const closeMatches = div.innerText.match(new RegExp('}}', 'g')) || [];
  if (openMatches.length !== closeMatches.length) {
    return translate('Open tags {{ count is not equal with close tags }} count');
  }
};
