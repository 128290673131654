import React from 'react';

import { processHtml } from './functions';

export const SafeHtmlWrapper = ({ html }: { html: string }) => {
  const processedHtml = processHtml(html);

  return (
    <div className="safe-html-container">
      <div dangerouslySetInnerHTML={{ __html: processedHtml }} />
    </div>
  );
};
