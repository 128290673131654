import React, { useCallback, useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Modal from 'react-bootstrap/lib/Modal';

import { requestBackend } from '@eva/emf/app/utils/request';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';
import { stringifyError } from '@eva/emf/app/shared/functions';
import { SkillTaxonomyTreeItemForm } from '@eva/emf/app/entities/skillTaxonomy/SkillTaxonomyTreeItemForm';
import { onExpandGroup } from '@eva/emf/app/entities/skillTaxonomy/functions';
import { TextArea } from '@eva/emf/app/shared/ui/TextArea/TextArea';

import { useFetchSkillTaxonomyOptions, useFetchSkillTaxonomyTree } from '../../../entities/SkillTaxonomies/hooks';
import {
  updateEntityTree,
  updateSkillTaxonomyNote,
} from '../../../widgets/SkillTaxonomies/SkillTaxonomiesTreeList/api';

import { ModalSkillTaxonomyLoader } from './ModalSkillTaxonomyLoader';

export const ModalSkillTaxonomyWidget = ({ show, onHide, skillTaxonomyId }) => {
  const [addSkillsNote, setAddSkillNote] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadProfileError, setLoadProfileError] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [skillTaxonomyTree, setSkillTaxonomyTree] = useState<any>(null);

  const { data: skillTaxonomiesData, isLoading: skillTaxonomiesLoading } = useFetchSkillTaxonomyOptions();

  const skillTaxonomies = skillTaxonomiesData?.skillTaxonomies || [];

  const { data: skillTaxonomyTreeData, isLoading: skillTaxonomyTreeLoading } = useFetchSkillTaxonomyTree({
    skillTaxonomyId,
  });

  const isLoading = skillTaxonomiesLoading || skillTaxonomyTreeLoading || loadingProfile;

  const skillTaxonomy = skillTaxonomies.find((taxonomy) => taxonomy.skillTaxonomyId === skillTaxonomyId);

  useEffect(() => {
    setSkillTaxonomyTree(skillTaxonomyTreeData);
  }, [skillTaxonomyTreeData]);

  const onSubmit = useCallback(
    async (value) => {
      try {
        await updateEntityTree({
          skillTaxonomyId,
          skills:
            value?.skills?.map(({ skillId, skillProficiencyValue }) => ({
              skillId,
              skillProficiencyValue,
            })) || [],
        });

        await updateSkillTaxonomyNote({ skillTaxonomyId, note: value.note });
        onHide();
      } catch (error) {
        return { [FORM_ERROR]: stringifyError(error) };
      }
    },
    [onHide, skillTaxonomyId],
  );

  const loadSelectedSkills = useCallback(() => {
    setLoadingProfile(true);
    requestBackend('/my/candidate-profile')
      .then(({ skillTaxonomies }) => {
        const skillTaxonomy =
          skillTaxonomies.find((taxonomy: any) => taxonomy.skillTaxonomyId === skillTaxonomyId) || {};
        const { skills, note } = skillTaxonomy;

        setLoadingProfile(false);
        setLoadProfileError(null);
        setInitialValues({ skills, note });
      })
      .catch((error) => {
        setLoadingProfile(false);
        setLoadProfileError(stringifyError(error));
      });
  }, [skillTaxonomyId]);

  useEffect(() => {
    loadSelectedSkills();
  }, [loadSelectedSkills]);

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitError, submitting }) => {
          const error = submitError || loadProfileError;

          if (isLoading) {
            return <ModalSkillTaxonomyLoader />;
          }

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {translate('{{skillTaxonomyName}} : Select skills', {
                    skillTaxonomyName: skillTaxonomy.name,
                  })}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {skillTaxonomyTree && (
                  <div className="skill-modal-wrapper">
                    <div className="skill-taxonomies-container">
                      <SkillTaxonomyTreeItemForm
                        isUpdatingEntity={false}
                        skillTaxonomyTree={skillTaxonomyTree}
                        skillProficiencies={skillTaxonomy.proficiencies}
                        isDisabled={false}
                        isEditSkill
                        onExpandGroup={onExpandGroup({
                          skillTaxonomyTree,
                          setSkillTaxonomyTree,
                        })}
                      />
                    </div>
                  </div>
                )}
                {addSkillsNote && (
                  <div className="form-group no-margin skill-comment">
                    <Field name="note" component={TextArea} label={translate('Other skills')} />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {error && <h4 className="text-danger text-center">{error}</h4>}

                <button
                  type="button"
                  onClick={() => setAddSkillNote(true)}
                  disabled={submitting || addSkillsNote}
                  className="btn btn-primary btn-sm pull-left"
                >
                  {translate('Add other')}
                </button>
                <button type="submit" disabled={submitting} className="btn btn-success btn-sm">
                  {submitting && <SpinnerSmall />} {translate('Save')}
                </button>
                <button type="button" onClick={onHide} disabled={submitting} className="btn btn-default btn-sm">
                  {translate('Cancel')}
                </button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Modal>
  );
};
