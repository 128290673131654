/* eslint-disable no-restricted-properties */
import './style.css';
import React, { useRef, useCallback } from 'react';

import UserDropdown from '@eva/emf/app/shared/ui/UserDropdown';
import { localStorageService } from '@eva/emf/app/services/localStorage';
import { getNextPortalPageUrl, getUserDropdownLinks, redirectTo, redirectToExternalUrl } from 'shared/functions';
import { routesNames } from 'shared/constants';

import ModalTermsAndConditions from 'containers/ModalTermsAndConditions';

interface MessengerChatHeaderProps {
  userProfile: any;
  dispatchSignOut: () => void;
}

const MessengerChatHeader: React.FC<MessengerChatHeaderProps> = ({ userProfile, dispatchSignOut }) => {
  const modalTermsAndConditionsRef = useRef(null);

  const renderUserDropdown = useCallback(() => {
    const authTypes = localStorageService.authTypes;
    const user = localStorageService.user;

    if (!!authTypes.length && user.userId) {
      return (
        <UserDropdown
          links={getUserDropdownLinks()}
          email={userProfile.emails?.primary?.emailAddress}
          displayName={userProfile.name?.displayName}
          dispatchSignOut={dispatchSignOut}
        />
      );
    }
  }, [dispatchSignOut, userProfile.emails?.primary?.emailAddress, userProfile.name?.displayName]);

  const redirectToPortal = useCallback(() => {
    if (!userProfile.accountTypes) {
      redirectTo(`/${routesNames.candidate}`);
    }

    try {
      const portalPage = getNextPortalPageUrl(userProfile.accountTypes);
      portalPage === `/${routesNames.candidate}` ? redirectTo(portalPage) : redirectToExternalUrl(portalPage);
    } catch (e) {
      dispatchSignOut();
    }
  }, [dispatchSignOut, userProfile.accountTypes]);

  const handleClickLegalTerms = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    modalTermsAndConditionsRef.current.open();
  };

  return (
    <div className="candidate-header-container">
      <ModalTermsAndConditions key="termsAndConditionsModal" ref={modalTermsAndConditionsRef} size="large" />
      <div className="chatbody-hld candidate-header-content">
        <ol className="breadcrumb pull-left">
          <li>
            <a onClick={redirectToPortal}>
              <i className="top-logo ll-icon ll-logo-eva-small fa-1x" />
            </a>
          </li>
          <li>{translate('Powered by EVA.ai')}</li>
          <li>
            <a onClick={handleClickLegalTerms}>{translate('Legal terms')}</a>
          </li>
        </ol>
        {renderUserDropdown()}
      </div>
      <div className="clear" />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default MessengerChatHeader;
