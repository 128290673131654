import PropTypes from 'prop-types';
import React from 'react';

import AIMessage from 'containers/AIUI/AIMessage';

import { useFileUploader } from '../fileUploader';

import { ContentEditor } from './ContentEditor';
import TopicsSelector from './TopicsSelector';

function BottomForm(props) {
  const {
    chat,
    messages,
    lastMessage,
    acceptExtensions,
    setTopicsMode,
    delayScrollDown,
    emitSendMessageAndScroll,
    updateMessagesHeight,
    selectionMode,
    textareaFocused,
    awaitingAI,
    topicsMode,
    mobileMode,
    bottomMode,
    newCandidate,
  } = props;

  const { setUploadButtonRef, resetFile, file, fileUrl, progress } = useFileUploader({
    acceptExtensions,
  });

  const uploadingFile = file && progress !== 1;

  const [content, setContent] = React.useState('');

  React.useLayoutEffect(() => {
    updateMessagesHeight();
  }, [content, file, progress, updateMessagesHeight]);

  const sendMessage = React.useCallback(
    (evt) => {
      evt.preventDefault();
      const uploadingFile = file && progress !== 1;
      if (uploadingFile || (!content && !file)) {
        return;
      }
      emitSendMessageAndScroll(content, file, fileUrl, 'webchat');
      setContent('');
      resetFile();
    },
    [content, emitSendMessageAndScroll, file, fileUrl, progress, resetFile],
  );

  const hideTextInput = Boolean(lastMessage?.options?.multiSelect);

  return (
    <form key="form" className="write candidate" onSubmit={sendMessage}>
      <div
        style={{
          visibility: (selectionMode || !messages.length) && !bottomMode ? 'hidden' : 'visible',
        }}
      >
        {topicsMode && (
          <TopicsSelector
            setTopicsMode={setTopicsMode}
            delayScrollDown={delayScrollDown}
            emitSendMessageAndScroll={emitSendMessageAndScroll}
          />
        )}

        {bottomMode && (
          <div className="text-center">
            <AIMessage
              key={lastMessage.messageId}
              content={lastMessage.content}
              options={lastMessage.options}
              index={messages.length - 1}
              acceptExtensions={acceptExtensions}
              messages={messages}
              emitSendMessage={emitSendMessageAndScroll}
              delayScrollDown={delayScrollDown}
              bottomMode
              controlsOnly
              editable
            />
          </div>
        )}

        {!bottomMode && !topicsMode && !hideTextInput && (
          <ContentEditor
            key="messenger-textarea"
            mobileMode={mobileMode}
            textareaFocused={textareaFocused}
            newCandidate={newCandidate}
            file={file}
            uploadingFile={uploadingFile}
            awaitingAI={awaitingAI}
            chat={chat}
            acceptExtensions={acceptExtensions}
            content={content}
            setContent={setContent}
            delayScrollDown={delayScrollDown}
            setTopicsMode={setTopicsMode}
            sendMessage={sendMessage}
            setUploadButtonRef={setUploadButtonRef}
            fileUrl={fileUrl}
            progress={progress}
            resetFile={resetFile}
          />
        )}
      </div>
    </form>
  );
}

BottomForm.propTypes = {
  chat: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  lastMessage: PropTypes.object,
  acceptExtensions: PropTypes.string.isRequired,
  delayScrollDown: PropTypes.func.isRequired,
  setTopicsMode: PropTypes.func.isRequired,
  emitSendMessageAndScroll: PropTypes.func.isRequired,
  selectionMode: PropTypes.bool,
  textareaFocused: PropTypes.bool,
  awaitingAI: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  topicsMode: PropTypes.bool,
  mobileMode: PropTypes.bool.isRequired,
  bottomMode: PropTypes.bool.isRequired,
  newCandidate: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default BottomForm;
