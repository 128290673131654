/* eslint-disable no-restricted-properties */
import PropTypes from 'prop-types';
import React from 'react';
import startCase from 'lodash/startCase';

import { request, requestBackend } from '@eva/emf/app/utils/request';
import { baseEndpoint, messagesLevelsTypes, authMethods as methods, iframeMode } from '@eva/emf/app/shared/constants';
import { getQueryVariables, setQueryVariables, validatePassword } from '@eva/emf/app/shared/functions';
import { PageSpinner } from '@eva/emf/app/shared/ui/Spinner';
import authService from '@eva/emf/app/services/auth';
import { stringifyError } from 'shared/functions';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

const socialRedirect = (provider) => {
  const socialType = provider === methods.sso ? 'saml' : provider;

  const token = `?token=${localStorage.token}`;
  const redirectPath = `&redirect=${encodeURIComponent('candidate/chat')}`;

  const href = `${baseEndpoint}/api/authentication/auth/${socialType}/sign-up${token}${redirectPath}`;

  if (iframeMode) {
    window.open(href, '_blank').focus();
  } else {
    window.location.href = href;
  }
  window.sendGAEvent(`CMSignIn${startCase(provider)}`, 'click', 'CMSignIn');
};

class Authenticate extends AIUIComponent {
  state = {
    authMethods: [],
    password: '',
    errorPassword: '',
    error: getQueryVariables().error,
    isLoading: false,
  };

  authenticate = async (evt) => {
    try {
      if (evt) {
        evt.preventDefault();
      }

      this.setState({
        authenticating: true,
        isLoading: true,
      });

      const data = await request(`${baseEndpoint}/api/authentication/auth/set-password`, {
        method: 'POST',
        body: JSON.stringify({
          token: localStorage.token,
          password: this.state.password,
        }),
      });
      // @ts-expect-error
      const authTypes = data.authMethods ? Object.keys(data.authMethods) : [];

      authService.signIn({
        // @ts-expect-error
        authTypes,
      });

      setQueryVariables({
        error: undefined,
      });
    } catch (error) {
      this.setState({
        showCredentials: false,
        isLoading: false,
        error: stringifyError(error),
      });
    } finally {
      this.setState({
        authenticating: false,
      });
    }
  };
  abortController: any;
  static contextTypes: { settings: PropTypes.Validator<object> };

  async componentDidMount() {
    this.abortController = new AbortController();

    try {
      this.setState({
        isLoading: true,
      });

      const profile = await requestBackend('/authentication/my/profile', {
        signal: this.abortController.signal,
      });

      // @ts-expect-error
      const authMethods = Object.entries(profile.authMethods).reduce(
        // @ts-expect-error
        (acc, [authMethod, { isAvailable }]) => (isAvailable ? [...acc, authMethod] : acc),
        [],
      );

      this.setState({ authMethods });
    } catch (error) {
      this.setState({
        message: {
          type: messagesLevelsTypes.danger,
          label: error.payload?.error,
        },
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  renderPasswordButton = (
    <div className="col-sm-4 col-xs-12 margin-min-vertical" key={methods.password}>
      <a
        className="btn btn-block btn-default btn-social"
        onClick={() => {
          this.setState({ showCredentials: true });
          window.sendGAEvent('CMSignInEmail', 'click', 'CMSignIn');
        }}
      >
        <i className="fa fa-envelope" />
        {translate('Provide password')}
      </a>
    </div>
  );

  renderSocialButton(provider) {
    return (
      <div key={provider} className="col-sm-4 col-xs-12 margin-min-vertical">
        <a className={`btn btn-block btn-${provider} btn-social`} onClick={() => socialRedirect(provider)}>
          <i className={`fa fa-${provider}`} />
          {translate('Sign in with')} {provider === methods.sso ? 'your corporate account' : startCase(provider)}
        </a>
      </div>
    );
  }

  clearState = () => {
    this.setState({
      errorPassword: '',
      password: '',
      showCredentials: false,
    });
  };

  render() {
    const { editable } = this.props;
    const { showCredentials, password, authenticating, authMethods, errorPassword, isLoading, error } = this
      .state as any;

    if (!editable) {
      return null;
    }

    if (isLoading) {
      return <PageSpinner />;
    }

    if (showCredentials) {
      return (
        <div className="row">
          <div className="text-left col-md-offset-3 col-md-6">
            <div
              className="pull-right"
              onClick={() => {
                this.setState({
                  errorPassword: '',
                });
                this.clearState();
              }}
              style={{
                cursor: 'pointer',
                marginTop: '-2px',
              }}
            >
              <i className="ll-icon ll-close" />
            </div>
            <div className="form-group">
              <label>{translate('Password')}</label>
              <input
                type="password"
                className="form-control"
                placeholder={translate('Password')}
                onChange={(evt) => {
                  this.setState({ password: evt.target.value });
                  this.setState({ errorPassword: validatePassword(evt.target.value) });
                }}
                value={password}
              />
              {errorPassword && <div className="text-danger">{errorPassword}</div>}
            </div>
            <button
              type="submit"
              className="btn btn-block btn-primary"
              disabled={!password || errorPassword || authenticating}
              onClick={(evt) => this.authenticate(evt)}
            >
              {translate('Sign in')}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        {error && <div className="text-danger">{error}</div>}
        {authMethods.map((provider) => {
          if (provider === methods.password) {
            return this.renderPasswordButton;
          }
          if (methods[provider]) {
            return this.renderSocialButton(provider);
          }
        })}
      </div>
    );
  }
}

Authenticate.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Authenticate;
