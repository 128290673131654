// Import sagas
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fromJS } from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension';

import AppSagas from 'containers/App/sagas';
import MessengerSagas from 'containers/MessengerCandidate/sagas';

import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
  // routerMiddleware(history),
];

const enhancers = [applyMiddleware(...middlewares)];

// @ts-expect-error
export const store = createStore(createReducer(), fromJS({}), composeWithDevTools(...enhancers));

// @ts-expect-error
store.runSaga = sagaMiddleware.run;
// @ts-expect-error
store.asyncReducers = {};

// @ts-expect-error
AppSagas.map(store.runSaga);
// @ts-expect-error
MessengerSagas.map(store.runSaga);

window.store = store;
