import React from 'react';
import get from 'lodash/get';
import { submit } from 'redux-form';

import { processValidationErrors } from '@eva/emf/app/shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { copy, whitelistLocation, whitelistName, whitelistPayment, whitelistSalary, logError } from 'shared/functions';
import type { GenericContextType } from '@eva/emf/app/types/generic-context';
import { GenericContext } from '@eva/emf/app/shared/constants';
import { putMyCandidateProfile } from '@eva/emf/app/shared/api/cbe';

import type { SectionPaneSharedStateType, SectionPaneSharedType } from './types';

import { renderCardEditButton, renderTopSubmitBlock, renderBottomSubmitBlock } from './functions';

const entitiesDescriptor = {
  entityIdKey: 'userId',
};

const returnValues = (values) => values;
const promiseResolve = () => Promise.resolve();

export class SectionPaneShared extends React.Component<SectionPaneSharedType, SectionPaneSharedStateType> {
  context: GenericContextType;
  unmounted: boolean;
  state = {
    initialValues: {},
  } as SectionPaneSharedStateType;

  UNSAFE_componentWillMount() {
    this.loadInitialValues();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, paneSettings } = nextProps;

    if (this.props.entity !== entity || this.props.paneSettings !== paneSettings) {
      this.loadInitialValues(nextProps);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }
  static contextType = GenericContext;

  onSubmit = async (immutableValues) => {
    const { entity, entitiesType, switchEditMode, updateEntity, paneSettings } = this.props;
    const { entityIdKey } = entitiesDescriptor;
    const {
      onSubmit,
      onBeforeSubmit = promiseResolve,
      submitPath = `/${entitiesType}`,
      prepareValues = returnValues,
      submitBody = {},
    } = paneSettings;

    if (!(onSubmit || submitPath)) {
      logError(paneSettings);
      throw new Error('Wrong paneSettings object - onSubmit and submitPath missing!');
    }

    const jsValues = immutableValues.toJS ? immutableValues.toJS() : immutableValues;
    const values = prepareValues(copy(jsValues));
    whitelistLocation(values.location);
    whitelistLocation(values.address);
    whitelistName(values.name);

    whitelistSalary(get(values, 'currentSalary.salary'));
    whitelistSalary(get(values, 'desiredSalary.salary'));

    whitelistPayment(values.payment);
    if (values.payment) {
      values.payment = Object.values(values.payment).find((item) => item) ? values.payment : null;
    }

    // We need to load object, but NOT put it in the list, but without change it will still be object
    if (values.companyId && typeof values.companyId === 'object') {
      values.companyId = values.companyId.companyId;
    }
    if (values.employerId && typeof values.employerId === 'object') {
      values.employerId = values.employerId.userId || null; // Null for empty employer - remove after BE update
    }

    if (values.tags) {
      values.tags = values.tags.map(({ tagId }) => tagId);
    }

    delete values.created; // See created: !entityId below

    this.setState({ submitting: true });

    const entityId = entity[entityIdKey];

    try {
      await onBeforeSubmit(this, values);
    } catch {
      if (!this.unmounted) {
        this.setState({ submitting: false });
      }
      return;
    }

    try {
      const updatedEntity = await putMyCandidateProfile({
        ...submitBody,
        ...values,
      });

      if (!this.unmounted) {
        this.setState({ submitting: false });
      }

      updateEntity({
        [entityIdKey]: entityId,
        created: !entityId,
        ...updatedEntity,
      });

      switchEditMode('');
    } catch (err) {
      if (this.unmounted) {
        return;
      }

      if (Array.isArray(err?.payload?.errors)) {
        processValidationErrors(values, jsValues)(err);
      } else {
        processValidationErrors(values, jsValues)([err]);
      }
    } finally {
      this.setState({
        submitting: false,
      });
    }
  };

  submitForm = (evt) => {
    const { formName } = this.props;
    evt.preventDefault();
    window.store.dispatch(submit(formName));
  };

  loadInitialValues(props = this.props) {
    const { isAllowedOperation } = this.context;
    const { entity, paneSettings } = props;
    const { Forms, loadInitialValues } = paneSettings;

    if (!Forms) {
      return;
    } else if (!paneSettings) {
      logError(paneSettings);
      throw new Error('paneSettings object missing!');
    } else if (paneSettings.viewPermission && !isAllowedOperation(paneSettings.viewPermission)) {
      return;
    }

    this.setState({
      entity,
      initialValues: loadInitialValues ? loadInitialValues(entity) : {},
    });
  }

  render() {
    const { isAllowedOperation } = this.context;
    const {
      settings,
      entity,
      selectedEntity,
      formName,
      paneSettings,
      entitiesType,
      editOptions,
      updateEntity,
      editMode,
      mobileMode,
      closePane,
      loadEntity,
    } = this.props;
    const { initialValues, submitting } = this.state;
    const { entityIdKey } = entitiesDescriptor;

    const { Cards, Forms, viewPermission, editPermission } = paneSettings;

    if (viewPermission && !isAllowedOperation(viewPermission)) {
      return null;
    }

    const switchEditMode =
      Forms && (!editPermission || isAllowedOperation(editPermission)) ? this.props.switchEditMode : null;
    const submitArgs: [boolean, (...arg: any) => void, any, (...arg: any) => void] = [
      submitting,
      switchEditMode,
      undefined,
      !entity[entityIdKey] && closePane,
    ];

    return (
      <div className="cards-candidate">
        {!editMode && Cards && (
          <div>
            {switchEditMode && renderCardEditButton(switchEditMode)}
            <Cards
              settings={settings}
              entity={entity}
              selectedEntity={selectedEntity}
              entitiesType={entitiesType}
              switchEditMode={switchEditMode}
              editOptions={editOptions}
              updateEntity={updateEntity}
              loadEntity={loadEntity}
              mobileMode={mobileMode}
            />
          </div>
        )}
        {editMode && Forms && (
          <form className="edit-user" onSubmit={this.submitForm} style={{ opacity: submitting ? 0.5 : 1 }}>
            {submitting && (
              <div className="text-center padding">
                <Spinner />
              </div>
            )}
            {renderTopSubmitBlock(...submitArgs)}
            <Forms
              form={formName}
              onSubmit={this.onSubmit}
              entitiesType={entitiesType}
              initialValues={initialValues}
              switchEditMode={switchEditMode}
              editOptions={editOptions}
              entity={entity}
              updateEntity={updateEntity}
              mobileMode={mobileMode}
            />
            {renderBottomSubmitBlock(...submitArgs)}
          </form>
        )}
      </div>
    );
  }
}
