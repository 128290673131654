import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm, Field } from 'redux-form/immutable';

import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';
import { validateRequired } from 'shared/validations';

const FormLanguage = ({ initialValues, editOptions, submitting, handleSubmit, setItemEditMode }) => (
  <form className="edit-user" onSubmit={handleSubmit}>
    <hr className="dotted" />
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <Field
          component={RenderSelect}
          name="languageId"
          valueKey="languageId"
          labelKey="name"
          options={editOptions.languages}
          label={translate('Language')}
          validate={validateRequired}
        />
      </div>
      <div className="col-xs-12 col-md-6">
        <Field
          component={RenderSelect}
          name="languageProficiency"
          valueKey="languageProficiencyId"
          labelKey="name"
          options={editOptions.languageProficiencies}
          label={translate('Language proficiency')}
          nullify
        />
      </div>
    </div>
    <div className="text-right">
      <button type="submit" id="submit-language" className="btn btn-sm btn-success" disabled={submitting}>
        {submitting && <SpinnerSmall />} {translate('Submit')}
      </button>
      <button
        id="cancel-edit-language"
        type="button"
        className="btn btn-sm btn-default margin-left"
        disabled={submitting}
        onClick={() => {
          const { candidateLanguageId } = initialValues.toJS();
          setItemEditMode(candidateLanguageId || null, false);
        }}
      >
        {translate('Cancel')}
      </button>
    </div>
  </form>
);

FormLanguage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setItemEditMode: PropTypes.func.isRequired,
};

// @ts-expect-error
// eslint-disable-next-line import/no-default-export
export default reduxForm({})(FormLanguage); // eslint-disable-line no-class-assign
